import { render, staticRenderFns } from "./GameTable.vue?vue&type=template&id=22024fda&scoped=true"
import script from "./GameTable.vue?vue&type=script&lang=js"
export * from "./GameTable.vue?vue&type=script&lang=js"
import style0 from "./GameTable.vue?vue&type=style&index=0&id=22024fda&prod&scoped=true&lang=css"
import style1 from "./GameTable.vue?vue&type=style&index=1&id=22024fda&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22024fda",
  null
  
)

export default component.exports